html,
body,
#root,
#root>div {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./_assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserratitalic';
  src: local('Montserratitalic'), url(./_assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiTextField-root {
  margin-top: 0px !important;
}

.MuiDrawer-root .MuiGrid-item>div {
  margin-top: 5px !important;
}

.MuiDrawer-root .MuiGrid-item>div>.MuiFormControlLabel-root {
  margin: 10px !important;
}

.Mui-disabled-button {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}


blink {
  -webkit-animation: 2s linear infinite condemned_blink_effect;
  /* for Safari 4.0 - 8.0 */
  animation: 2s linear infinite condemned_blink_effect;
}

/* for Safari 4.0 - 8.0 */
@-webkit-keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes flashing {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.MuiDialogTitle-root {
  text-align: center;
}


/* Headings */
main .MuiToolbar-root {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

main .MuiToolbar-root h6,
main .heading-h6 {
  font-size: 1.5rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
  color: white;
  width: auto;
  padding: 5px !important;
  font-size: 20px;
  text-align: left;
  font-family: Roboto;
  background: linear-gradient(135deg, #102843 76%, white 24%);
  margin-left: 0px;
  padding-left: 14px !important;
  padding-right: 100px !important;
}

main .MuiTypography-h6.heading-h6 {
  margin: 14px;
  margin-left: 0px;
  float: left;
  padding: 5px;
}

.MuiPaper-root .MuiPaper-root {
  box-shadow: none !important;
}